import {
  Box,
  CircularProgress,
  FormControl,
  Radio,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Card } from '@cw-ui/surface-components';
import { styled } from '@mui/material';
import { formatMessage } from 'cw-ui-core';
import Locale from 'src/Locale';
import { injectIntl } from 'react-intl';
import { DataGridPro } from '@mui/x-data-grid-pro';
import DotTile from 'src/components/Navigation/DotsMenuItem/DotTile';
import css from '../../Navigation/DotsMenuItem/DotTile.scss';
import Status from '../../Status';
import { setMuiLicense } from '@cw-ui/utils';

const AsioSecondStep = props => {
  setMuiLicense();
  const [selectedValue, setSelectedValue] = useState('');
  const { filterProducts } = props;

  const handleChange = event => {
    setSelectedValue(event.target.value);
    props.onPSAInstanceChange(event.target.value);
  };

  const columns = [
    {
      field: 'psa_instances',
      headerName: 'PSA Instances',
      width: 180,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <FormControl sx={{ p: 0.5, flexDirection: 'row', gap: 1, pl: '0px !importnat' }}>
            <Radio
              sx={{
                alignItems: 'flex-start',
                padding: '0px',
                marginTop: '2px',
                height: 'fit-content'
              }}
              checked={selectedValue === row.id.toString()}
              onChange={handleChange}
              value={row.id.toString()}
            />
            <div>
              <Typography
                color="primary"
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  fontSize: '12px',
                  fontFamily: 'sans-serif'
                }}
              >
                <DotTile product={row} customStyle={css.styleSecondScreen} />
              </Typography>
              <Typography
                title={row.productInfo1}
                component="p"
                sx={{
                  fontSize: '12px',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '120px',
                  overflow: 'hidden',
                  fontFamily: 'sans-serif'
                }}
              >
                {row.productInfo1}
              </Typography>
            </div>
          </FormControl>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 90,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Status type={row.hasActiveInstance ? 'active' : 'inactive'}>
            {row.hasActiveInstance ? 'Active' : 'Inactive'}
          </Status>
        );
      }
    },
    {
      field: 'payment',
      headerName: 'Payment',
      width: 150,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <div
            style={{ height: '100%', display: 'flex', alignItems: 'center' }}
          >
            <Status type={row.purchased ? 'active' : 'inactive'}>
              {row.purchased ? 'Purchased' : 'Not Purchased'}
            </Status>
          </div>
        );
      }
    },
    {
      field: 'instance_type',
      headerName: 'Instance Type',
      sortable: false,
      width: 150,
      renderCell: ({ row }) => {
        return (
          <div
            style={{ height: '100%', display: 'flex', alignItems: 'center' }}
          >
            {row.installType}
          </div>
        );
      }
    }
  ];

  const SCompatibleApplications = styled(Card)(() => ({
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  }));

  const CompatibleApplications = ({ filterProducts }) => {
    const [products, setProducts] = useState([]);

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
      if (products.length === 0) {
        props.onPSAInstanceChange(true);
      } else if (
        products.length >= 1 &&
        products.every(product => product.installType !== 'Production')
      ) {
        props.onPSAInstanceChange(true);
      } else if (
        products.length >= 1 &&
        products.some(product => product.installType === 'Production')
      ) {
        props.onPSAInstanceChange(selectedValue);
      }
    });

    useEffect(() => {
      if (products.length <= 0) {
        setLoading(true);
      }

      let data = filterProducts;
      if (data && products.length <= 0) {
        setLoading(false);
        data = mapProducts(data);
        setProducts(data);
      }
    }, [filterProducts, products.length]);

    const mapProducts = filterProducts => {
      return filterProducts.map((product, index) => ({
        ...product,
        id: index
      }));
    };

    return (
      <SCompatibleApplications>
        <div style={{ width: '100%' }}>
          <DataGridPro
            sx={{
              height: '380px',
              overflowY: 'auto',
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#fff',
                color: '#101120'
              },
              '& .disabled-row': {
                backgroundColor: 'rgba(211,211,211,0.3)',
                color: 'gray',
                pointerEvents: 'none',
                opacity: '0.4'
              }
            }}
            rows={products}
            checkboxSelection={false}
            columns={columns}
            slots={{ toolbar: null }}
            slotProps={{ toolbar: null }}
            getRowHeight={() => 'auto'}
            hideFooter
            getRowId={row => row.id}
            loading={isLoading}
            disableColumnMenu
            getRowClassName={params =>
              params.row.installType !== 'Production' ? 'disabled-row' : ''
            }
            isRowSelectable={params => params.row.installType === 'Production'}
            isCellEditable={params => params.row.installType === 'Production'}
            components={{
              LoadingOverlay: () => (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="380px"
                >
                  <CircularProgress />
                </Box>
              )
            }}
          />
        </div>
      </SCompatibleApplications>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pl: 2,
        pr: 2
      }}
    >
      <Typography
        fontWeight="300"
        pl="16px"
        fontSize="26px"
        sx={{ fontFamily: 'sans-serif' }}
      >
        {formatMessage(Locale.asio_step2_title)}
      </Typography>
      <Typography
        variant="body2"
        fontSize="13px"
        pl="16px"
        mb="16px"
        fontWeight="400"
        color="#767786"
        lineHeight="18px"
        sx={{ fontFamily: 'sans-serif' }}
      >
        {formatMessage(Locale.asio_step2_sub)}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          fontFamily: 'sans-serif'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: '16px',
            width: '55%'
          }}
        >
          <CompatibleApplications filterProducts={filterProducts} />
        </Box>
        <Box

          component="img"
          src="/images/AsioPage/Dialog-illustration2.png"
          width="45%"
          height="400px"
        />
      </Box>
    </Box>
  );
};

export default injectIntl(AsioSecondStep);
