import React, { } from "react";
import { Link } from '@cw-ui/navigation-components';
import * as core from 'cw-ui-core';
import { connect } from 'react-redux';
import { FEEDBACK_DIALOG_ID } from "./FeedbackDialogContainer";
import Loader from "src/LoaderCustom";

const mapStateToProps = state => {
    return {
        feedbackValue: core.getScreenData(state.screen, ['issueTitle'], FEEDBACK_DIALOG_ID),
        isFetching: core.getScreenData(state.screen, ['isLoading'], FEEDBACK_DIALOG_ID),
    };
};

const FeedbacklinkComp = props => {
    const { item, onFeedbackSubmitClick, feedbackValue, isFetching } = props;
    return (

        isFetching ? (<Loader />)
            : (<Link
                underline="hover"
                onClick={onFeedbackSubmitClick}
                variant="body3"
                disabled={!!!feedbackValue}
            >
                {item.link}
            </Link >)
    );
};

const Feedbacklink = connect(mapStateToProps, null)(FeedbacklinkComp);
export default Feedbacklink;