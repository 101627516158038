import * as core from 'cw-ui-core';
import LocationService from 'src/services/Api/HomeApi/LocationService';
import PartnerService from 'src/services/Api/HomeApi/PartnersService';
import CompanyService from 'src/services/Api/HomeApi/CompanyService';
import { refreshProducts } from 'src/services/Products/ProductsService';
import Routes from 'src/services/Routes';
import UserService from 'src/services/Api/HomeApi/UserService';
import Locale from 'src/Locale';
import {
  isFeatureEnabled,
  FEATURES
} from 'src/services/Features/FeaturesService';
import AsioSelfService from '../Api/AsioApi/AsioSelfService';

const LOCATION_REQUIREMENTS = {
  Canada: {
    requiresState: true,
    requiresZip: false
  },
  Australia: {
    requiresState: true,
    requiresZip: false
  },
  'United States': {
    requiresState: false,
    requiresZip: true
  },
  'United Kingdom': {
    requiresState: false,
    requiresZip: true
  },
  default: {
    requiresState: false,
    requiresZip: false
  }
};

export const getUser = async () => {
  const state = core.Store().getState();
  const userExists = core.getModuleCache(state.module, ['user']);
  if (!userExists) {
    const result = await UserService.getUserAsync();
    if (!result.isSuccess) {
      const errorMessage = 'Failed to load user. ';
      const error = errorMessage + result.error;
      core.CwLog.error(error);
      core
        .Store()
        .dispatch(
          core.setErrorScreenMessage(errorMessage, false, result.errorMessages)
        );
      return;
    }

    const user = result.response;
    core.Store().dispatch(core.setModuleCache(['user'], user));
    return user;
  } else {
    return userExists.toJS();
  }
};

export const getCountryCurrency = async country => {
  const dispatch = core.Store().dispatch;
  const result = await LocationService.getCountryCurrency(country);
  if (!result.isSuccess) {
    const errorMessage = 'Failed to get partner currency. ';
    const error = errorMessage + (result.error || result.errorMessages);
    core.CwLog.error(error);
    dispatch(
      core.setErrorScreenMessage(errorMessage, false, result.errorMessages)
    );
    return;
  }

  return result.response.currency;
};

/** @returns {PartnerViewModel} */
export const getPartner = async () => {
  const dispatch = core.Store().dispatch;
  const result = await PartnerService.getPartnerProfile();
  if (!result.isSuccess) {
    const errorMessage = 'Failed to get partner. ';
    const error = errorMessage + result.error;
    core.CwLog.error(error);
    core
      .Store()
      .dispatch(
        core.setErrorScreenMessage(errorMessage, false, result.errorMessages)
      );
    return;
  }

  const partner = result.response;
  dispatch(core.setModuleCache(['partner'], partner));
  return partner;
};

/** @returns {PartnerViewModel} */
export const getPartnerOrCache = async () => {
  const state = core.Store().getState();
  var cachedResult = core.getModuleCache(state.module, ['partner']);
  if (cachedResult)
     return cachedResult.toJS();
   else
     return await getPartner();
 };

export class PartnerViewModel {
  id;
  companyId;
  companyName;
  country;
  crmAccountId;
  currency;
  cwCompanyId;
  friendlyName;
  phoneNumber;
  zip;
  created;
}

export const getStatesByCountry = country => {
  const state = core.Store().getState();
  const countriesWithStatesCheck = core.getScreenData(state.screen, [
    'partnerLocation',
    'countriesWithStates'
  ]);
  const countriesWithStates = countriesWithStatesCheck
    ? countriesWithStatesCheck.toJS()
    : [];
  const countryStates = countriesWithStates.filter(
    item => item.country === country
  );
  let statesList = [];
  const sorted_states =
    countryStates.length > 0 ? countryStates[0].states.sort() : [];
  for (let i = 0; i < sorted_states.length; i++) {
    statesList.push({ id: sorted_states[i], name: sorted_states[i] });
  }

  return statesList;
};

export const getCountries = async () => {
  const dispatch = core.Store().dispatch;
  const result = await LocationService.getCountries();
  if (!result.isSuccess) {
    const errorMessage = 'Failed to load countries. ';
    const error = errorMessage + result.error;
    core.CwLog.error(error);
    dispatch(
      core.setErrorScreenMessage(errorMessage, false, result.errorMessages)
    );
    return;
  }

  const countriesWithStates = result.response;
  let countries = [];
  let countriesList = [];
  countriesWithStates.map(item => countries.push(item.country));
  const sorted_keys = Object.values(countries).sort();
  for (let i = 0; i < sorted_keys.length; i++) {
    countriesList.push({ id: sorted_keys[i], name: sorted_keys[i] });
  }

  dispatch(
    core.setScreenData(
      ['partnerLocation', 'countriesWithStates'],
      countriesWithStates
    )
  );
  dispatch(core.setScreenData(['partnerLocation', 'countries'], countriesList));
  return countriesList;
};

export const checkIfEmailVerified = async () => {
  const state = core.Store().getState();
  const isEmailConfirmed = core.getModuleCache(state.module, [
    'user',
    'emailConfirmed'
  ]);
  if (!isEmailConfirmed) {
    await UserService.refreshUserSecurity();
    const result = await UserService.getUserAsync();
    if (!result.isSuccess) {
      const errorMessage = 'Failed to load user. ';
      const error = errorMessage + result.error;
      core.CwLog.error(error);
      core
        .Store()
        .dispatch(
          core.setErrorScreenMessage(errorMessage, false, result.errorMessages)
        );
      return;
    }

    const user = result.response;
    core.Store().dispatch(core.setModuleCache(['user'], user));
    return user.emailConfirmed;
  }

  return isEmailConfirmed;
};

export const sendVerificationEmail = async () => {
  const result = await UserService.sendConfirmationEmail();
  if (!result.isSuccess) {
    const errorMessage = 'Failed to send verification email. ';
    const error = errorMessage + result.error;
    core.CwLog.error(error);
    core
      .Store()
      .dispatch(
        core.setErrorScreenMessage(errorMessage, false, result.errorMessages)
      );
  }
};

export const getPartnerAccountRequiredFields = async () => {
  const state = core.Store().getState();
  const partner = await getPartner();
  const country =
    partner?.country ||
    core.getScreenData(state.screen, ['partnerLocation', 'country']);
  const locationRequirements =
    LOCATION_REQUIREMENTS[country] || LOCATION_REQUIREMENTS['default'];

  let requiredFields = {
    country: !partner?.country,
    state: locationRequirements.requiresState && !partner?.state,
    zip: locationRequirements.requiresZip && !partner?.zip,
    phone: !partner?.phoneNumber
  };
  requiredFields = Object.fromEntries(
    Object.entries(requiredFields).filter(value => {
      return value[1] === true;
    })
  );

  const hasRequiredFields = Object.keys(requiredFields).length > 0;

  core
    .Store()
    .dispatch(
      core.setScreenData(
        ['partnerLocation', 'requiredFields'],
        hasRequiredFields ? requiredFields : undefined
      )
    );
  return hasRequiredFields ? requiredFields : undefined;
};

export const isRequiredFieldsMissingSaved = () => {
  const state = core.Store().getState();
  const requiredFieldsCheck = core.getScreenData(state.screen, [
    'partnerLocation',
    'requiredFields'
  ]);
  if (requiredFieldsCheck) {
    let saved = true;
    const requiredFields = requiredFieldsCheck.toJS();
    if (
      requiredFields.country &&
      !core.getModuleCache(state.module, ['partner', 'country'])
    ) {
      saved = false;
    }

    if (
      requiredFields.state &&
      !core.getModuleCache(state.module, ['partner', 'state'])
    ) {
      saved = false;
    }

    if (
      requiredFields.zip &&
      !core.getModuleCache(state.module, ['partner', 'zip'])
    ) {
      saved = false;
    }

    if (
      requiredFields.phone &&
      !core.getModuleCache(state.module, ['partner', 'phoneNumber'])
    ) {
      saved = false;
    }

    return saved;
  }

  return true;
};

export const isRequiredFieldsMissing = () => {
  const state = core.Store().getState();
  const requiredFieldsCheck = core.getScreenData(state.screen, [
    'partnerLocation',
    'requiredFields'
  ]);
  if (requiredFieldsCheck) {
    const requiredFields = requiredFieldsCheck.toJS();
    if (
      requiredFields.country &&
      !core.getScreenData(state.screen, ['partnerLocation', 'country'])
    ) {
      return true;
    }

    if (
      requiredFields.state &&
      !core.getScreenData(state.screen, ['partnerLocation', 'state'])
    ) {
      return true;
    }

    if (
      requiredFields.zip &&
      !core.getScreenData(state.screen, ['partnerLocation', 'zip'])
    ) {
      return true;
    }

    if (
      requiredFields.phone &&
      !core.getScreenData(state.screen, ['partnerLocation', 'phone'])
    ) {
      return true;
    }
  }

  return false;
};

const updatePartnerPhoneNumber = async phone => {
  const result = await PartnerService.updatePartnerPhoneNumber(phone);
  const dispatch = core.Store().dispatch;
  if (!result.isSuccess) {
    const errorMessage = 'Failed to update partner phone number. ';
    const error = errorMessage + result.error;
    core.CwLog.error(error);
    dispatch(
      core.setErrorScreenMessage(errorMessage, false, result.errorMessages)
    );
    return;
  }

  const partner = result.response;
  dispatch(core.setModuleCache(['partner'], partner));
  return partner;
};

export const updateRequiredFields = async () => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const requiredFieldMissingChk = core.getScreenData(state.screen, [
    'partnerLocation',
    'requiredFields'
  ]);
  const requiredFieldsMissing = requiredFieldMissingChk
    ? requiredFieldMissingChk.toJS()
    : undefined;
  if (!requiredFieldsMissing) {
    return;
  }

  const country = requiredFieldsMissing.country
    ? core.getScreenData(state.screen, ['partnerLocation', 'country'])
    : core.getModuleCache(state.module, ['partner', 'country']);
  const partnerState = requiredFieldsMissing.state
    ? core.getScreenData(state.screen, ['partnerLocation', 'state'])
    : core.getModuleCache(state.module, ['partner', 'state']);
  const zip = requiredFieldsMissing.zip
    ? core.getScreenData(state.screen, ['partnerLocation', 'zip'])
    : core.getModuleCache(state.module, ['partner', 'zip']);
  const phone = requiredFieldsMissing.phone
    ? core.getScreenData(state.screen, ['partnerLocation', 'phone'])
    : core.getModuleCache(state.module, ['partner', 'phoneNumber']);
  const currency = await updatePartnerCurrency(country, partnerState, zip);
  if (!currency) {
    return;
  } else {
    dispatch(core.setModuleCache(['partner', 'country'], country));
    dispatch(core.setModuleCache(['partner', 'state'], partnerState));
    dispatch(core.setModuleCache(['partner', 'zip'], zip));
  }

  if (phone) {
    return await updatePartnerPhoneNumber(phone);
  }
};

export const updatePartnerCurrency = async (country, partnerState, zip) => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const partner = await getPartner();
  let currency;
  if (partner && partner.currency !== null) {
    currency = partner.currency;
  } else {
    const partnerCountry = core.getModuleCache(state.module, [
      'partner',
      'country'
    ]);
    currency = await getCountryCurrency(
      partnerCountry && partnerCountry.length ? partnerCountry : country
    );
    dispatch(core.setModuleCache(['partner', 'currency'], currency));
  }

  await PartnerService.updatePartnerCountryStateZip(
    country,
    partnerState,
    zip,
    currency
  );
  return currency;
};

export const attemptToGetPartnerCurrency = async country => {
  const state = core.Store().getState();
  let countryName = country;
  if (!country) {
    countryName = core.getScreenData(state.screen, [
      'partnerLocation',
      'country'
    ]);
  }

  if (!country) {
    countryName = core.getModuleCache(state.module, ['partner', 'country']);
  }

  const currency = await getCountryCurrency(countryName);
  if (currency) {
    const dispatch = core.Store().dispatch;
    dispatch(core.setModuleCache(['partner', 'currency'], currency));
  }
};

export const lookupCompany = async () => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const result = await CompanyService.lookupCompany();
  if (!result.isSuccess) {
    if (result.status === 404) {
      dispatch(core.removeModuleCache(['company']));
      return null;
    }

    const errorMessage = 'Failed to lookup company. ';
    const error = errorMessage + result.error;
    core.CwLog.error(error);
    dispatch(
      core.setErrorScreenMessage(errorMessage, false, result.errorMessages)
    );
    return;
  }

  var company = result.response;
  const partnerCompanyId = core.getModuleCache(state.module, [
    'partner',
    'companyId'
  ]);
  dispatch(core.setModuleCache(['company'], company));
  dispatch(core.setModuleCache(['partner', 'companyId'], company.companyRecId));
  dispatch(
    core.setModuleCache(['partner', 'cwCompanyId'], company.companyIdentifier)
  );
  if (!partnerCompanyId) {
    refreshProducts();
  }

  return company;
};

export const lookupVerifiedCompany = async () => {
  const dispatch = core.Store().dispatch;
  const company = await lookupCompany();
  if (!company) {
    const error = 'Failed to lookup company.';
    core.CwLog.error(error);
    dispatch(core.setErrorScreenMessage(error, false));
    return;
  }

  const companyNotVerified = !company.partnerId;
  if (companyNotVerified) {
    dispatch(
      core.showDialog('COMPANY_NOT_VERIFIED', {
        error: company.companyAssociationError
      })
    );
    showUnverifiedAccountMessage(true, isCrmAccountNotVerified());
    return;
  }

  return company;
};

export const isVaildPartner = async () => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const isPartnerCheck = core.getModuleCache(state.module, [
    'company',
    'isPartner'
  ]);
  if (isPartnerCheck === undefined || isPartnerCheck === null) {
    dispatch(core.setScreenData(['showLoadingComponent'], true));
    const result = await AsioSelfService.getCheckPartner();
    dispatch(core.setModuleCache(['company', 'isPartner'], result.response));
    dispatch(core.setScreenData(['showLoadingComponent'], false));
  }
};

export const isEmailNotVerified = () => {
  const state = core.Store().getState();
  return !core.getModuleCache(state.module, ['user', 'emailConfirmed']);
};

export const isCompanyNotVerified = company => {
  const switchToNoCompany = isFeatureEnabled(FEATURES.SWITCH_TO_NO_COMPANY);
  const state = core.Store().getState();
  const partnerModule = core.getModuleCache(state.module, ['partner']).toJS();
  let isNoManageCompany = false;

  if (partnerModule.crmAccountId) {
    isNoManageCompany = true;
  }

  if (switchToNoCompany) {
    return !isNoManageCompany;
  } else {
    const state = core.Store().getState();
    const companyVerified =
      (company && company.partnerId) ||
      core.getModuleCache(state.module, ['company', 'partnerId']);
    return !companyVerified;
  }
};

export const isCrmAccountNotVerified = () => {
  const state = core.Store().getState();

  const crmAccountId = core.getModuleCache(state.module, [
    'partner',
    'crmAccountId'
  ]);

  return !crmAccountId;
};

export const showUnverifiedAccountMessage = (
  isCompanyNotVerified,
  isCrmAccountNotVerified
) => {
  const dispatch = core.Store().dispatch;
  let unverifiedEntities = [];

  if (isCompanyNotVerified) {
    unverifiedEntities.push('business information');
  }

  if (isCrmAccountNotVerified) {
    unverifiedEntities.push('company account');
  }

  if (unverifiedEntities.length > 0) {
    dispatch(
      core.setInformationalScreenMessage(
        core.formatMessage(Locale.company_account_verification_error, {
          connectWiseSupport: `<a href="mailto:help@connectwise.com?subject=Unable%20to%20Verify%20Account%20in%20ConnectWise%20Home">${core.formatMessage(
            Locale.company_account_support_text
          )}</a>`,
          entity: unverifiedEntities.join(' and ')
        }),
        true,
        null,
        Routes.HOME_PAGE.id
      )
    );
  }
};
