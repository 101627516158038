import { getModuleCache } from 'cw-ui-core';
import { connect } from 'react-redux';
import AsioSecondStep from 'src/components/AsioEnablement/AsioEnablementDialog/AsioSecondStep';

const mapStateToProps = state => {
  let filterProducts = [];
  let products = getModuleCache(state.module, ['products']);
  filterProducts = products
    ? products
        .toJS()
        .filter(product => product.name.toLowerCase().includes('manage'))
    : undefined;
  return {
    filterProducts
  };
};

export default connect(mapStateToProps, null)(AsioSecondStep);
