/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Checkbox, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { formatMessage, setScreenData, Store } from 'cw-ui-core';
import { Card } from '@cw-ui/surface-components';
import Locale from 'src/Locale';
import userAgreement from './UserAgreement';
import { FormItem, TextInput } from '@cw-ui/form-components';
import { injectIntl } from 'react-intl';
import { ASIO_ENABLEMENT_DIALOG_ID } from './AsioEnablementDialogContainer';
import { Alert } from '@cw-ui/feedback-components';

const AsioFirstStep = props => {
  const { email, companyName, region, asioMemberCode, errors } = props;

  const { dispatch } = Store() || {};

  const [memberCode, setMemberCode] = useState(
    asioMemberCode ? asioMemberCode : companyName
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMsg, setErrorMsg] = useState([]);

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (errors && errors.length > 0) {
      if (errorMessage === errors[0]) return;
      setErrorMessage(errors[0]);

      setErrorMsg(errors);
    }
  }, [errors]);

  const handleMemberCodeChange = useCallback(
    event => {
      if (event.target.value.trim() === '') {
        setErrorMessage('Please enter the member code');
        props.setErrors(true);
      } else {
        setErrorMessage(false);
        props.setErrors(false);
      }

      setMemberCode(event.target.value.replace(/\s/g, ''));
      dispatch(
        setScreenData(
          'memberCode',
          event.target.value.replace(/\s/g, ''),
          ASIO_ENABLEMENT_DIALOG_ID
        )
      );
    },
    [dispatch, props]
  );

  const userAgreementClick = useCallback(
    event => {
      setIsChecked(event.target.checked);
      props.onAgreementChange(event.target.checked);
    },
    [props]
  );

  const isMobile = useMediaQuery('(min-width: 768px)');

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        pl: 2,
        pr: 2
      }}
    >
      {errorMsg && errorMsg.length > 0 && (
        <Alert
          sx={{ width: '90%' }}
          variant="outlined"
          severity="error"
          onClose={() => setErrorMsg([])}
        >
          <ul>
            {errorMsg.map((x, i) => (
              <li key={i}>{x}</li>
            ))}
          </ul>
        </Alert>
      )}
      <Typography
        variant="accordion"
        mb={0.5}
        pl={2}
        fontWeight={700}
        fontFamily="ceraPro"
        color="#27366D"
        lineHeight="25.14px"
        sx={{ fontFamily: 'sans-serif' }}
      >
        {formatMessage(Locale.connectwise_asio_platform)}
      </Typography>
      <Typography
        fontWeight={300}
        pl={2}
        fontSize={26}
        sx={{ fontFamily: 'sans-serif' }}
      >
        {formatMessage(Locale.asio_step1_title)}
      </Typography>
      <Typography
        variant="paragraph"
        fontSize={13}
        sx={{ fontFamily: 'sans-serif' }}
        pl={2}
        mb={0.5}
        fontWeight={400}
        color="#767786"
        lineHeight="18px"
      >
        {formatMessage(Locale.asio_step1_sub)}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          justifyContent: 'space-between',
          fontFamily: 'sans-serif'
        }}
      >
        <Stack spacing={1} sx={{ width: isMobile ? '50%' : '95%', pl: '16px' }}>
          <FormItem
            className="textInputFormControl"
            id="regionItem"
            labelProps={{
              disabled: true,
              error: false,
              required: false
            }}
            labelText="Region"
            shrink
            size="small"
          >
            <TextInput
              id="region"
              label="Region"
              placeholder="Select a region"
              size="small"
              value={region}
            />
          </FormItem>
          <FormItem
            className="textInputFormControl"
            id="emailAddressItem"
            labelProps={{
              disabled: true,
              error: false,
              required: false
            }}
            labelText="Email Address"
            shrink
            size="small"
          >
            <TextInput
              id="emailAddress"
              label="Email Address"
              placeholder="Enter email address"
              size="small"
              value={email}
            />
          </FormItem>
          <FormItem
            className="textInputFormControl"
            id="memberCodeItem"
            labelProps={{
              disabled: false,
              error: errorMessage,
              required: true
            }}
            labelText="Member Code"
            shrink
            size="small"
          >
            <TextInput
              id="memberCode"
              label="Member Code"
              onChange={handleMemberCodeChange}
              placeholder="Enter member id code"
              size="small"
              value={memberCode || ''}
              required
              error={errorMessage}
              // helperText={errorMessage}
            />
          </FormItem>
          <Typography
            variant="paragraph"
            fontSize={13}
            sx={{ fontFamily: 'sans-serif', marginTop: '0px !important' }}
            mb={0.5}
            fontWeight={400}
            color="#767786"
          >
            Member code is a unique identifier to your Asio instance which can
            also be further used to have a sub-domain of other ConnectWise
            applications like ScreenConnect, BrightGauge, IT Boost.
          </Typography>
          <Card
            variant="default"
            sx={{
              p: 2,
              mt: '8px',
              backgroundColor: '#EDF5FF',
              width: 'calc(100% - 32px)',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#EDF5FF !important'
              }
            }}
          >
            <Typography fontWeight={600} mb={0.5} fontSize={12} color="#101120">
              {formatMessage(Locale.license_service_agreement)}
            </Typography>
            <Box sx={{ maxHeight: 120, overflowY: 'auto', width: '100%' }}>
              <Typography fontWeight={600} fontSize={10} color="#101120">
                {formatMessage(Locale.asio_service_agreement_title)}
              </Typography>
              <br />
              <Typography fontWeight={600} fontSize={10} color="#101120">
                {formatMessage(Locale.asio_service_agreement_sub)}
              </Typography>
              <br />
              <Typography fontSize={10}>
                {formatMessage(Locale.asio_service_agreement_sub2)}
              </Typography>
              <br />
              {userAgreement.map(agreement => (
                <div key={agreement.heading}>
                  <Typography fontWeight="bold" fontSize={10}>
                    {agreement.heading}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={10}
                    whiteSpace="break-spaces"
                  >
                    {agreement.paragraph}
                  </Typography>
                </div>
              ))}
            </Box>
            <Checkbox
              style={{ marginLeft: '-11px' }}
              checked={isChecked}
              onChange={userAgreementClick}
            />
            <span
              style={{ fontSize: 12, fontFamily: 'inter', fontWeight: 400 }}
            >
              {formatMessage(Locale.service_agreement_sub)}
              <span style={{ color: '#D32F2F' }}>
                {formatMessage(Locale.service_agreement_sub2)}
              </span>
            </span>
          </Card>
        </Stack>
        {isMobile && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '50%'
            }}
          >
            <Box
              component="img"
              src="/images/AsioPage/Dialog-illustration.png"
              width="90%"
              height="70%"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default injectIntl(AsioFirstStep);
