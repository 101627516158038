import React from 'react';
import * as core from 'cw-ui-core';

import { UPDATE_TICKET_STATUS_DIALOG } from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/TicketUpdateStatusDialog/TicketUpdateStatusDialog';
import { UPDATE_CONTACT_DIALOG_ID } from 'src/components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/UpdateContactDialog/UpdateContactDialog';
import { CLOSE_ACCOUNT_CONFIRMATION_DIALOG_ID } from 'src/components/Drawer/UserProfileDrawer/UserProfileCloseAccountConfirmationDialog';
import { ADD_EDIT_RESOURCE_DIALOG } from 'src/components/HomePage/ToolsPod/Resources/ResourcesDialogs/AddEditResourceDialog';
import { DELETE_RESOURCE_DIALOG } from 'src/components/HomePage/ToolsPod/Resources/ResourcesDialogs/DeleteConfirmResourceDialog';
import { ADD_EDIT_CONFIRM_RESOURCE_DIALOG } from 'src/components/HomePage/ToolsPod/Resources/ResourcesDialogs/AddEditConfirmResourceDialog';
import { BANNER_PREVIEW_DIALOG_ID } from './components/Admin/NewsPage/Banner/BannerPreviewDialog/BannerPreviewDialogContainer';
import { ASIO_ENABLEMENT_DIALOG_ID } from './components/AsioEnablement/AsioEnablementDialog/AsioEnablementDialogContainer';
import { FEEDBACK_DIALOG_ID } from './components/AsioEnablement/Feedback/FeedbackDialogContainer';
import { FEEDBACK_RESOLVED_DIALOG_ID } from './components/AsioEnablement/Feedback/FeedbackResolvedContainer';

// TODO: will have to move this in order to leverage completely async components
export const CLOSE_ACCOUNT_DIALOG = 'CLOSE_ACCOUNT_DIALOG';
export const ERROR_CLOSE_ACCOUNT_DIALOG = 'ERROR_CLOSE_ACCOUNT_DIALOG';
export const ADD_TO_CART_DIALOG = 'ADD_TO_CART_DIALOG';
export const START_TRIAL_DIALOG = 'START_TRIAL_DIALOG';
export const APP_PREREQUISITES_DIALOG = 'APP_PREREQUISITES_DIALOG';
export const REQUEST_FOR_QUOTE_CONFIRMATION_DIALOG =
  'REQUEST_FOR_QUOTE_CONFIRMATION_DIALOG';
export const TRIAL_CREATION_DIALOG = 'TRIAL_CREATION_DIALOG';
export const SUPPORT_ENABLED_DIALOG = 'SUPPORT_ENABLED_DIALOG';
export const DELETE_NEWS_ADMIN_POST_DIALOG = 'DELETE_NEWS_ADMIN_POST_DIALOG';
export const UNPIN_NEWS_ADMIN_POST_DIALOG = 'UNPIN_NEWS_ADMIN_POST_DIALOG';
export const CUSTOMER_WALLET_NOT_FOUND = 'CUSTOMER_WALLET_NOT_FOUND';
export const FOLLOW_KNOWN_ISSUES_DIALOG = 'FOLLOW_KNOWN_ISSUES_DIALOG';
export const KNOWN_ISSUES_SEND_EMAIL_DIALOG = 'KNOWN_ISSUES_SEND_EMAIL_DIALOG';
export const REDIRECT_OFFSITE_DIALOG = 'REDIRECT_OFFSITE_DIALOG';
export const GENERIC_CONFIRMATION_DIALOG = 'GENERIC_CONFIRMATION_DIALOG';
export const PUBLISH_BANNER_DIALOG = 'PUBLISH_BANNER_DIALOG';
export const DELETE_BANNER_DIALOG = 'DELETE_BANNER_DIALOG';

const getDialog = (dialogID, props = null) => {
  switch (dialogID) {
    case 'WHEN_CHARGED':
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/ShoppingCart/WhenChargedDialog/WhenChargedDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case GENERIC_CONFIRMATION_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/GenericConfirmationDialog/GenericConfirmationDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case TRIAL_CREATION_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/HomePage/TrialCreationDialog/TrialCreationDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case 'EMAIL_VERIFICATION':
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/EmailVerificationDialog/EmailVerificationDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case 'COMPANY_NOT_VERIFIED':
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/ShoppingCart/CompanyNotVerifiedDialog/CompanyNotVerifiedDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case 'REMOVE_CART_ITEM':
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/ShoppingCart/RemoveCartItemDialog/RemoveCartItemDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case 'CART_ACCESS_DENIED':
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/AccessDeniedDialog/CartAccessDeniedDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case 'APP_ACCESS_DENIED':
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/AccessDeniedDialog/AppAccessDeniedDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case 'SERVER_NOT_AVAILABLE':
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/AccessDeniedDialog/ServerNotAvailableDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case REDIRECT_OFFSITE_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import('./components/RedirectDialog/RedirectDialogContainer').then(
              component => {
                cb(component);
              }
            );
          }}
        />
      );
    case 'INVALID_CARD':
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/ShoppingCart/InvalidCardDialog/InvalidCardDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case 'PARTNER_CODE':
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/ShoppingCart/PartnerCodeDialog/PartnerCodeDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case 'CURRENCY_MISMATCH':
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/ShoppingCart/CurrencyMismatchDialog/CurrencyMismatchDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case ADD_TO_CART_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/AddToCartDialog/AddToCartDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case UPDATE_TICKET_STATUS_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/TicketUpdateStatusDialog/TicketUpdateStatusDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case UPDATE_CONTACT_DIALOG_ID:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/PartnerSupportPage/SupportTickets/EditSupportTicketDrawer/UpdateContactDialog/UpdateContactDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case START_TRIAL_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/StartTrialDialog/StartTrialDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case APP_PREREQUISITES_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import('./components/App/AppPrerequisitesDialogContainer').then(
              component => {
                cb(component);
              }
            );
          }}
        />
      );
    case REQUEST_FOR_QUOTE_CONFIRMATION_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/Drawer/ProductDrawer/RequestForQuoteConfirmationDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case CLOSE_ACCOUNT_CONFIRMATION_DIALOG_ID:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/Drawer/UserProfileDrawer/UserProfileCloseAccountConfirmationDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case SUPPORT_ENABLED_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/Drawer/UserProfileDrawer/CompanyAccountSetting/SupportEnabledDialog/SupportEnabledDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case DELETE_NEWS_ADMIN_POST_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/Admin/NewsPage/NewsFeed/dialogs/DeleteNewsAdminDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case UNPIN_NEWS_ADMIN_POST_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/Admin/NewsPage/NewsFeed/dialogs/UnpinNewsAdminDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case CUSTOMER_WALLET_NOT_FOUND:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import('./components/Wallet/WalletNotFoundContainer').then(
              component => {
                cb(component);
              }
            );
          }}
        />
      );
    case ADD_EDIT_RESOURCE_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/HomePage/ToolsPod/Resources/ResourcesDialogs/AddEditResourceDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case DELETE_RESOURCE_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/HomePage/ToolsPod/Resources/ResourcesDialogs/DeleteConfirmResourceDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case ADD_EDIT_CONFIRM_RESOURCE_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/HomePage/ToolsPod/Resources/ResourcesDialogs/AddEditConfirmResourceDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case FOLLOW_KNOWN_ISSUES_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/PartnerSupportPage/KnownIssues/FollowKnownIssuesDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case KNOWN_ISSUES_SEND_EMAIL_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/PartnerSupportPage/KnownIssues/KnownIssuesSendEmailLinkDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case PUBLISH_BANNER_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/Admin/NewsPage/Banner/PublishBannerDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );

    case 'BRAND_COLOR_DIALOG':
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/BrandColorDialog/BrandColorDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );

    case BANNER_PREVIEW_DIALOG_ID:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/Admin/NewsPage/Banner/BannerPreviewDialog/BannerPreviewDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case DELETE_BANNER_DIALOG:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/Admin/NewsPage/Banner/Dialogs/DeleteBannerDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case ASIO_ENABLEMENT_DIALOG_ID:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/AsioEnablement/AsioEnablementDialog/AsioEnablementDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case FEEDBACK_DIALOG_ID:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/AsioEnablement/Feedback/FeedbackDialogContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case FEEDBACK_RESOLVED_DIALOG_ID:
      return (
        <core.AsyncComponent
          key={dialogID}
          {...props}
          loader={cb => {
            import(
              './components/AsioEnablement/Feedback/FeedbackResolvedContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );

    default:
      return core.getCoreDialog(dialogID, props);
  }
};

export default getDialog;
