import * as core from 'cw-ui-core';

export const POLICIES = {
  UNDEFINED: 'Undefined',
  NO_COMPANY: 'No Company',
  NO_MANAGE_COMPANY: 'No Manage Company',
  NO_COMPANY_CONTACT: 'No Company Contact',
  ADMIN: 'Admin',
  BILLING_ADMIN: 'Billing Admin',
  USER_MANAGEMENT: 'User Management',
  USER_MANAGER: 'User Manager',
  USER_EDITOR: 'User Editor',
  ROLE_MANAGER: 'Role Manager',
  ROLE_EDITOR: 'Role Editor',
  AUTHENTICATION_MANAGER: 'Authentication Manager',
  AUTHENTICATION_EDITOR: 'Authentication Editor',
  VIEWER: 'Viewer',
  NO_ACCESS: 'No Access',
  NEWS_FEED_ADMIN: 'News Feed Admin',
  NEWS_FEED_POSTER: 'News Feed Poster',
  SECURITY_BULLETINS_ADMIN: 'Security Bulletins Admin',
  HOME_ADMIN: 'Home Admin',
  HOME_ADVANCED_USER: 'Home Advanced User',
  HOME_STANDARD_USER: 'Home Standard User',
  HOME_BASIC_USER: 'Home Basic User',
  HOME_RESTRICTED_USER: 'Home Restricted User',
  CONNECTWISE_COLLEAGUE: 'ConnectWise Colleague',
  HOME_RESOURCE_ADMIN: 'Home Resource Admin'
};

export const getUserSecurityPoliciesDisplayText = () => {
  const state = core.Store().getState();
  const securityPoliciesChk = core.getModuleCache(state.module, [
    'userSecurity',
    'securityPolicies'
  ]);

  const securityPolicies = securityPoliciesChk
    ? securityPoliciesChk.toJS()
    : [];

  //remove no manage company
  var noManageCompanyIndex = securityPolicies.indexOf(
    POLICIES.NO_MANAGE_COMPANY
  );

  if (noManageCompanyIndex > -1) {
    securityPolicies.splice(noManageCompanyIndex, 1);
  }

  return securityPolicies.join(', ');
};
