import { connect } from 'react-redux';
import FeedbackResolved from './FeedbackResolved';
import { getModuleConfig, hideDialog } from 'cw-ui-core';

export const FEEDBACK_RESOLVED_DIALOG_ID = 'FEEDBACK_RESOLVED';

const mapStateToProps = state => {
  const salesForceUrl = getModuleConfig(state.module, [
    'SalesForceCaseUrlBase'
  ]);
  const caseId = state.dialog.get(FEEDBACK_RESOLVED_DIALOG_ID).result.response.id;
  const caseUrl = salesForceUrl + caseId;
  return {
    caseNumber: state.dialog.get(FEEDBACK_RESOLVED_DIALOG_ID).result.response.number,
    caseUrl
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseIconClick: () => {
      dispatch(hideDialog(FEEDBACK_RESOLVED_DIALOG_ID));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackResolved);