/* eslint-disable react-hooks/exhaustive-deps */
import { FormItem, Textarea, TextInput } from "@cw-ui/form-components";
import { setScreenData, Store } from "cw-ui-core";
import React, { useCallback, useState } from "react";
import { FEEDBACK_DIALOG_ID } from "./FeedbackDialogContainer";

const TextFeild = ({ item }) => {

    const { dispatch } = Store() || {};

    const [issueValue, setIssueValue] = useState('');
    const [value, setValue] = useState('');

    const handleIssueChange = useCallback(e => {
        setIssueValue(e.target.value);
        dispatch(setScreenData('issueTitle', e.target.value, FEEDBACK_DIALOG_ID));
    }, []);

    const handleSummaryChange = useCallback(e => {
        setValue(e.target.value);
        dispatch(setScreenData('issueSummary', e.target.value, FEEDBACK_DIALOG_ID));
    }, []);

    return (
        <>
            <FormItem
                className="textInputFormControl"
                id={`issue${item.id}`}
                labelProps={{
                    disabled: false,
                    error: false,
                    required: true
                }}
                labelText="Issue"
                shrink
                size="small"
            >
                <TextInput
                    id={`issue${item.id}`}
                    label="Issue"
                    placeholder="Please enter the issue"
                    size="small"
                    onChange={event => handleIssueChange(event)}
                    value={issueValue} />
            </FormItem>
            <FormItem
                className="textAreaFormControl"
                id={`summary${item.id}`}
                labelProps={{
                    disabled: false,
                    error: false,
                    required: false
                }}
                labelText="Summary"
                shrink
            >
                <Textarea
                    count={`${value.length}/100`}
                    id={`summary${item.id}`}
                    label="Summary"
                    maxRows={6}
                    minRows={3}
                    onChange={event => handleSummaryChange(event)}
                    placeholder="Enter Summary"
                    value={value} />
            </FormItem>
        </>
    );
};

export default TextFeild;