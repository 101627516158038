import React from 'react';
import { CwLog, AsyncComponent } from 'cw-ui-core';

const getScreen = (screenId, props = null) => {
  switch (screenId) {
    case 'HomePage':
      return (
        <AsyncComponent
          key={screenId}
          {...props}
          loader={cb => {
            import('./components/HomePage/HomePageContainer').then(
              component => {
                cb(component);
              }
            );
          }}
        />
      );
    case 'ShoppingCart':
      return (
        <AsyncComponent
          key={screenId}
          {...props}
          loader={cb => {
            import('./components/ShoppingCart/ShoppingCartContainer').then(
              component => {
                cb(component);
              }
            );
          }}
        />
      );
    case 'UniversityPage':
      return (
        <AsyncComponent
          key={screenId}
          {...props}
          loader={cb => {
            import(
              './components/FramedScreen/UniversityFramedScreenContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case 'DocumentationPage':
      return (
        <AsyncComponent
          key={screenId}
          {...props}
          loader={cb => {
            import(
              './components/FramedScreen/DocumentationFramedScreenContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case 'SsoUserManagementPage':
      return (
        <AsyncComponent
          key={screenId}
          {...props}
          loader={cb => {
            import(
              './components/FramedScreen/SsoUserMgmtFramedScreenContainer'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );
    case 'LogoutPage':
      return (
        <AsyncComponent
          key={screenId}
          {...props}
          loader={cb => {
            import('./components/LogoutPage/LogoutPage').then(component => {
              cb(component);
            });
          }}
        />
      );
    case 'BillingPage':
      return (
        <AsyncComponent
          key={screenId}
          {...props}
          loader={cb => {
            import('./components/BillingPage/InvoiceListPageContainer').then(
              component => {
                cb(component);
              }
            );
          }}
        />
      );
    case 'PartnerSupportRedirect':
      return (
        <AsyncComponent
          key={screenId}
          {...props}
          loader={cb => {
            import(
              './components/PartnerSupportPage/PartnerSupportRedirect'
            ).then(component => {
              cb(component);
            });
          }}
        />
      );

    case 'PartnerSupportPage':
      return (
        <AsyncComponent
          key={screenId}
          {...props}
          loader={cb => {
            import('./components/PartnerSupportPage/PartnerSupportPage').then(
              component => {
                cb(component);
              }
            );
          }}
        />
      );

    case 'IssuePage':
      return (
        <AsyncComponent
          key={screenId}
          {...props}
          loader={cb => {
            import('./components/IssuePage/IssuePage').then(component => {
              cb(component);
            });
          }}
        />
      );

    case 'NewsAdminPage':
      return (
        <AsyncComponent
          key={screenId}
          {...props}
          loader={cb => {
            import('src/components/Admin/NewsPage/NewsPageContainer').then(
              component => {
                cb(component);
              }
            );
          }}
        />
      );

    case 'AsioPage':
      return (
        <AsyncComponent
          key={screenId}
          {...props}
          loader={cb => {
            import('./components/AsioEnablement/AsioPageContainer').then(
              component => {
                cb(component);
              }
            );
          }}
        />
      );
    // case 'WhatNextPage':
    //   return (
    //     <AsyncComponent
    //       key={screenId}
    //       {...props}
    //       loader={cb => {
    //         import('./components/WhatsNext/WhatsNextList').then(
    //           component => {
    //             cb(component);
    //           }
    //         );
    //       }}
    //     />
    //   );

    default:
      CwLog.error(`No screen found for ${screenId}`);
  }
};

export default getScreen;
