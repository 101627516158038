import { connect } from 'react-redux';
import FeedbackDialog from './FeedbackDialog';
import { hideDialog, setErrorScreenMessage, setScreenData, showDialog } from 'cw-ui-core';
import { ASIO_ENABLEMENT_DIALOG_ID } from '../AsioEnablementDialog/AsioEnablementDialogContainer';
import { submitFeedback } from 'src/services/AsioService';
import { FEEDBACK_RESOLVED_DIALOG_ID } from './FeedbackResolvedContainer';

export const FEEDBACK_DIALOG_ID = 'FEEDBACK_DIALOG';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  const onFeedbackSubmitClick = async () => {
    dispatch(setScreenData('isLoading', true, FEEDBACK_DIALOG_ID));
    const result = await submitFeedback();
    if (result.isSuccess) {
      dispatch(hideDialog(FEEDBACK_DIALOG_ID));
      dispatch(showDialog(FEEDBACK_RESOLVED_DIALOG_ID, { result}));
    } else {
      const errorMessage = 'Failed to create case. ';
      dispatch(hideDialog(FEEDBACK_DIALOG_ID));
      dispatch(setErrorScreenMessage(errorMessage, false, []));
    }

    dispatch(setScreenData('isLoading', false, FEEDBACK_DIALOG_ID));
  };

  return {
    onCloseIconClick: () => {
      dispatch(hideDialog(FEEDBACK_DIALOG_ID));
    },
    onPreviousButtonClick: () => {
      dispatch(hideDialog(FEEDBACK_DIALOG_ID));
      dispatch(showDialog(ASIO_ENABLEMENT_DIALOG_ID));
    },
    onFeedbackSubmitClick
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDialog);
