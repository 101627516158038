import { Box, Typography } from '@mui/material';
import { formatMessage } from 'cw-ui-core';
import React from 'react';
import Locale from 'src/Locale';

const AsioFailureStep = ({ data }) => {
  const renderText = (
    text,
    variant = 'paragraph',
    fontSize = '13px',
    marginBottom = '16px'
  ) => (
    <Typography
      variant={variant}
      fontSize={fontSize}
      pl="16px"
      marginBottom={marginBottom}
      fontWeight="400"
      color="#767786"
      lineHeight="18px"
      sx={{ fontFamily: 'sans-serif' }}

    >
      {formatMessage(text)}
    </Typography>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'row',
        pl: '16px',
        pr: '16px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: '16px',
          width: '50%'
        }}
      >
        <Typography fontWeight="300" pl="16px" fontSize="26px" sx={{ fontFamily: 'sans-serif' }}>
          {formatMessage(Locale.asio_failure_step_title)}
        </Typography>
        <Typography fontWeight="300" pl="16px" fontSize="26px" sx={{ fontFamily: 'sans-serif' }}>
          {formatMessage(Locale.asio_failure_step_title2, {
            caseNumber: data.response?.caseNumber
          })}
        </Typography >
        <Typography sx={{ mt: '3%', display: 'flex', justifyContent: 'center', fontFamily: 'sans-serif' }}>
          {renderText(Locale.asio_failure_step_sub)}
        </Typography >
        <Typography sx={{ display: 'flex', justifyContent: 'center', fontFamily: 'sans-serif' }}>
          {renderText(Locale.asio_failure_step_sub2)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '50%'
        }}
      >
        <Box
          component="img"
          src="/images/AsioPage/DialogError.png"
          width="90%"
          height="70%"
        />
      </Box>
    </Box>
  );
};

export default AsioFailureStep;
