import {
  Dialog,
  DialogTitle,
  IconButton,
  Box,
  ThemeProvider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { formatMessage, hideDialog, setModuleCache, Store } from 'cw-ui-core';
import Locale from 'src/Locale';
import { getTheme } from '@cw-ui/asio-neon-themes';
import { Stepper } from '@cw-ui/navigation-components';
import AsioSuccessStep from './AsioSuccessStep';
import { Button } from '@cw-ui/form-components';
import AsioFirstStepContainer from './AsioFirstStepContainer';
import AsioFailureStep from './AsioFailureStep';
import AsioSecondStepContainer from './AsioSecondStepContainer';
import { ASIO_ENABLEMENT_DIALOG_ID } from './AsioEnablementDialogContainer';
import Loader from 'src/LoaderCustom';
import { getProducts } from 'src/services/Products/ProductsService';

const AsioEnablementDialog = props => {
  const theme = getTheme('light');
  const [activeStep, setActiveStep] = useState(
    props.asioDialogStep === 2
      ? 0
      : props.asioDialogStep
        ? props.asioDialogStep
        : 0
  );
  const [isAgreed, setIsAgreed] = useState(false);
  const [isPSASelected, setPSAInstanceClicked] = useState(false);
  const [error, setErrors] = useState(false);
  const [stepperWidth, setStepperWidth] = useState('50%');

  const [isLoading, setLoading] = useState(false);
  const [linkPSAInstanceResult, setLinkPSAInstanceResult] = useState('');
  const inputElement = useRef(null);

  const handleAgreementChange = isChecked => {
    setIsAgreed(isChecked);
  };

  const handlePSAInstanceChange = isChecked => {
    setPSAInstanceClicked(isChecked);
  };

  useEffect(() => {
    setActiveStep(
      props.asioDialogStep === 2
        ? 0
        : props.asioDialogStep
          ? props.asioDialogStep
          : 0
    );
  }, [props.asioDialogStep]);

  const [steps, setNewStep] = useState([
    {
      step: 0,
      title: 'Confirm Account Details',
      label: '',
      content: (
        <AsioFirstStepContainer
          onAgreementChange={handleAgreementChange}
          setErrors={setErrors}
        />
      )
    },
    {
      step: 1,
      title: 'Link PSA Instances',
      label: '',
      content: (
        <AsioSecondStepContainer
          onPSAInstanceChange={handlePSAInstanceChange}
        />
      )
    }
  ]);
  const handleNext = async () => {
    if (activeStep === 0) {
      setLoading(true);
    }

    const result = await props.validateAsioProvisioningData();
    const element = inputElement.current;
    const paperRef = element.querySelector('.MuiPaper-root');

    if (paperRef) {
      paperRef.scrollTop = 0;
    }

    if (result) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep);
    }

    if (activeStep === 0) {
      setLoading(false);
    }
  };

  const linkPSAInstanaceClick = async () => {
    setLoading(true);

    const result = await props.onLinkPSAInstanceClick();
    const element = inputElement.current;
    const paperRef = element.querySelector('.MuiPaper-root');

    if (paperRef) {
      paperRef.scrollTop = 0;
    }

    setLinkPSAInstanceResult(result);
    if (result.isSuccess && result.response?.instanceId) {
      setNewStep(prevSteps => [
        ...prevSteps,
        {
          step: 2,
          title: 'Success',
          label: '',
          content: <AsioSuccessStep />,
          completed: true
        }
      ]);
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setStepperWidth('75%');
    } else {
      setNewStep(prevSteps => [
        ...prevSteps,
        {
          step: 2,
          title: 'Error',
          label: '',
          error: true,
          content: <AsioFailureStep data={result} />,
          completed: false
        }
      ]);
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setStepperWidth('75%');
    }

    setLoading(false);
  };

  const handlePrevious = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setIsAgreed(false);
  };

  const isWhatsNext = activeStep === 2 && props.isSuccess;
  const isNeedSupport = activeStep === 0 || activeStep === 1;

  const handleonButtonClick = async () => {
    if (!isWhatsNext) return;
    setLoading(true);
    const dispatch = Store().dispatch;
    await getProducts(true);
    dispatch(setModuleCache('isWhatsNextPage', true));
    dispatch(hideDialog(ASIO_ENABLEMENT_DIALOG_ID));
    setLoading(false);
  };

  const getTitle = () => {
    if (isLoading) return <Loader />;
    return activeStep === 0
      ? formatMessage(Locale.confirm_account)
      : activeStep === 1
        ? formatMessage(Locale.activate_asio)
        : activeStep === 2 && !props.isSuccess && 'View Case';
  };

  function getWhatNextTitle() {
    if (isLoading) return <Loader />;
    return formatMessage(Locale.whats_next);
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
      ref={inputElement}
        open
        sx={{
          '& .MuiDialog-paper': {
            height: '90vh',
            width: '70vw'
          }
        }}
        maxWidth="lg"
        onClose={props.onCloseIconClick}
      >
        <Box
          display="flex"
          flexDirection="row"
          sx={{
            position: 'sticky',
            borderBottom: '1px solid #ccc',
            top: 0,
            zIndex: 2,
            backgroundColor: '#ffffff'
          }}
        >
          <DialogTitle
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '28px',
              padding: '8px 16px'
            }}
          >
            {activeStep === 2
              ? formatMessage(Locale.how_can_we_help)
              : formatMessage(Locale.lets_get_started)}
          </DialogTitle>
          {!props.isSuccess && <IconButton
            sx={{
              color: '#27366D',
              position: 'absolute',
              top: '4px',
              right: '8px'
            }}
            aria-label="close"
            onClick={props.onCloseIconClick}
          >
            <CloseIcon sx={{ height: '20px', width: '20px' }} />
          </IconButton>
          }
        </Box>
        <Stepper
          sx={{ width: stepperWidth, padding: '8px' }}
          activeStep={activeStep}
          orientation="horizontal"
          variant="progress"
          steps={steps}
        ></Stepper>
        <>{steps[activeStep].content}</>
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            padding: '8px',
            height: '100%',
            alignItems: 'flex-end',
            display: 'flex',
            justifyContent: 'space-between',
            zIndex: 6
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            color="ghost"
            sx={{
              textTransform: 'unset !important',
              fontWeight: '600',
              fontSize: '14px'
            }}
            onClick={
              activeStep === 0
                ? props.onCloseIconClick
                : activeStep === 1
                  ? handlePrevious
                  : activeStep === 2 && !props.isSuccess
                    ? () => props.onNeedSupportClick(activeStep)
                    : null
            }
          >
            {activeStep === 0
              ? formatMessage(Locale.cancel)
              : activeStep === 1
                ? formatMessage(Locale.previous)
                : activeStep === 2 && !props.isSuccess
                  ? 'Need Support?'
                  : null}
          </Button>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            {(activeStep === 2 && props.isSuccess) ||
              activeStep === 0 ||
              activeStep === 1 ? (
              <Button
                variant="outlined"
                size="medium"
                color="secondary"
                onClick={
                  isNeedSupport
                    ? () => props.onNeedSupportClick(activeStep)
                    : handleonButtonClick
                }
                sx={{
                  textTransform: 'unset !important',
                  fontWeight: '600',
                  fontSize: '14px'
                }}
              >
                {isNeedSupport
                  ? formatMessage(Locale.need_support)
                  : isWhatsNext
                    ? getWhatNextTitle()
                    : null}
              </Button>
            ) : null}
            {(activeStep === 2 && !props.isSuccess) ||
              activeStep === 0 ||
              activeStep === 1 ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={
                  activeStep === 0 ? !isAgreed || error : !isPSASelected
                }
                onClick={() => {
                  activeStep === 1
                    ? linkPSAInstanaceClick()
                    : activeStep === 2 && !props.isSuccess
                      ? props.onViewCaseClick(linkPSAInstanceResult)
                      : handleNext();
                }}
                sx={{
                  textTransform: 'unset !important',
                  fontSize: '14px',
                  backgroundColor: isLoading ? 'white' : ''
                }}
              >
                {getTitle()}
              </Button>
            ) : null}
          </Box>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};

export default injectIntl(AsioEnablementDialog);
