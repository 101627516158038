import * as core from 'cw-ui-core';
import ToolsService from 'src/services/Api/HomeApi/ToolsService';
import { getPartnerOrCache } from 'src/services/Partners/PartnersService';
import Routes from 'src/services/Routes';
import {
  getPartnerAccountRequiredFields,
  updateRequiredFields,
  isRequiredFieldsMissing
} from 'src/services/Partners/PartnersService';
import {
  START_TRIAL_DIALOG,
  TRIAL_CREATION_DIALOG,
  REQUEST_FOR_QUOTE_CONFIRMATION_DIALOG
} from 'src/DialogFactory';
import { IDENTIFY_PRODUCT_DRAWER } from 'src/components/Drawer/ProductDrawer/IdentifyProductDrawerContainer';
import { UNITE_PRODUCT_DRAWER } from 'src/components/Drawer/ProductDrawer/UniteProductDrawerContainer';
import { CONTROL_PRODUCT_DRAWER } from 'src/components/Drawer/ProductDrawer/ControlProductDrawerContainer';
import { exponentialDelay } from 'src/services/Utils';
import HasAccess from 'src/services/RoleAccess';
import { POLICIES } from 'src/services/Authorization';
import { MANAGE_PRODUCT_DRAWER } from 'src/components/Drawer/ProductDrawer/ManageProductDrawerContainer';
import { AUTOMATE_PRODUCT_DRAWER } from 'src/components/Drawer/ProductDrawer/AutomateProductDrawerContainer';
import { getCookie, setCookie } from 'src/services/Utils';
export const refreshProducts = async () => {
  const dispatch = core.Store().dispatch;
  const products = await getProducts(true);
  const identifyProduct = products.find(
    p => p.productIdentifier === 'identify'
  );
  dispatch(
    core.setScreenData(['product'], identifyProduct, IDENTIFY_PRODUCT_DRAWER)
  );
  const uniteProduct = products.find(p => p.productIdentifier === 'unite');
  dispatch(core.setScreenData(['product'], uniteProduct, UNITE_PRODUCT_DRAWER));
  const controlProduct = products.find(p => p.productIdentifier === 'control');
  dispatch(
    core.setScreenData(['product'], controlProduct, CONTROL_PRODUCT_DRAWER)
  );
  const manageProduct = products.find(p => p.productIdentifier === 'manage');
  dispatch(
    core.setScreenData(['product'], manageProduct, MANAGE_PRODUCT_DRAWER)
  );
  const automateProduct = products.find(
    p => p.productIdentifier === 'automate'
  );
  dispatch(
    core.setScreenData(['product'], automateProduct, AUTOMATE_PRODUCT_DRAWER)
  );
};

export const formTypes = {
  Quote: 'Request Quote',
  Demo: 'Request Demo',
  FirstUsageForm: 'First Usage Form'
};

export const hasVisibleFields = fields => {
  return (
    fields.filter(x => x.type !== 'hidden' && x.type !== 'submit').length > 0
  );
};

export const getProductPricingPlans = async productId => {
  const dispatch = core.Store().dispatch;
  var result = await ToolsService.getProductPricingPlans(productId);
  if (!result.isSuccess || result.response.status === 'error') {
    const errorMessage = 'Failed to load product pricing plans. ';
    const error = errorMessage + (result.error || result.response.msg);
    core.CwLog.error(error);
    dispatch(
      core.setErrorScreenMessage(errorMessage, false, result.errorMessages)
    );
    return;
  }

  var pricingPlans = result.response;
  dispatch(
    core.setScreenData(
      [Routes.HOME_PAGE.id, 'pricingPlans', productId],
      pricingPlans
    )
  );
  return pricingPlans;
};

const isLastProductRetryAttemptWithinThreshold = attempt => {
  const state = core.Store().getState();
  const lastProductRetryTimeStamp = core.getModuleCache(state.module, [
    'retries',
    'products',
    'timeStamp'
  ]);
  const lastProductRetryAttempt = core.getModuleCache(state.module, [
    'retries',
    'products',
    'attempt'
  ]);
  if (!lastProductRetryTimeStamp) return false;
  const now = new Date().getTime();
  if (now - lastProductRetryTimeStamp >= 30000) return false;
  if (lastProductRetryAttempt !== attempt) return false;
  return true;
};

export const getProducts = async (recache, retryCount = 0) => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const productsCheck = core.getModuleCache(state.module, ['products']);
  let products = productsCheck && !recache ? productsCheck.toJS() : [];
  if (products.length === 0) {
    const result = await ToolsService.getProducts();
    if (!result.isSuccess || result.response.status === 'error') {
      const errorMessage = 'Failed to load products. ';
      const error = errorMessage + (result.error || result.response.msg);
      core.CwLog.error(error);
      dispatch(
        core.setErrorScreenMessage(errorMessage, false, result.errorMessages)
      );
      return;
    }

    products = result.response;
    dispatch(core.setModuleCache(['products'], products));

    if (retryCount < 10 && products.some(p => p.provisioning)) {
      const attempt = retryCount + 1;
      if (!isLastProductRetryAttemptWithinThreshold(attempt)) {
        dispatch(
          core.setModuleCache(['retries', 'products'], {
            timeStamp: new Date().getTime(),
            attempt
          })
        );
        await exponentialDelay(30000, attempt, 5);
        getProducts(true, attempt);
      }
    }
  }

  return products;
};

export const getFormSignupFields = async (productId, formType) => {
  const state = core.Store().getState();
  const fieldsChk = core.getScreenData(
    state.screen,
    ['fields'],
    START_TRIAL_DIALOG
  );
  let fields = fieldsChk ? fieldsChk.toJS() : undefined;
  if (!fields) {
    const dispatch = core.Store().dispatch;
    const result = await ToolsService.getFormSignupFields(productId, formType);
    if (!result.isSuccess || result.response.status === 'error') {
      const errorMessage = 'Failed to get trial sign up fields. ';
      const error = errorMessage + (result.error || result.response.msg);
      core.CwLog.error(error);
      dispatch(
        core.setErrorScreenMessage(errorMessage, false, result.errorMessages)
      );
      return;
    }

    fields = result.response;
  }

  return fields;
};

export const allowStartTrial = async (productId, formType) => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  let fields = await getFormSignupFields(productId, formType);
  fields = fields.filter(field => field.type !== 'submit');
  let allowTrial = true;
  if (fields) {
    for (let field of fields) {
      const fieldValue = core.getScreenData(state.screen, [
        Routes.HOME_PAGE.id,
        'trialSignup',
        productId,
        field.fieldName
      ]);
      if (!fieldValue) {
        allowTrial = false;
        break;
      }
    }
  }

  dispatch(
    core.setScreenData(
      [Routes.HOME_PAGE.id, productId, 'allowStartTrial'],
      allowTrial
    )
  );
  return allowTrial;
};

export const startProvisioning = async product => {
  const dispatch = core.Store().dispatch;
  const result = await submitSignupData(product);
  if (result) {
    dispatch(core.showDialog(TRIAL_CREATION_DIALOG, { product }));
    return result;
  }
};

export const startTrial = async product => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const productId = product.productId;
  const hasSignupDataChk = core.getScreenData(state.screen, [
    Routes.HOME_PAGE.id,
    'trialSignup',
    productId
  ]);
  let formType = core.getScreenData(state.screen, [
    Routes.HOME_PAGE.id,
    'trialSignup',
    productId,
    'formType'
  ]);
  formType = formType ? formType : '';
  const hasRequiredFields = await getPartnerAccountRequiredFields();
  if (hasSignupDataChk) {
    const fields = await getFormSignupFields(productId, formType);
    dispatch(
      core.showDialog(START_TRIAL_DIALOG, {
        fields,
        product,
        hasRequiredFields
      })
    );
  } else {
    const fields = await getFormSignupFields(productId, formType);
    if (fields) {
      const allowTrial = await allowStartTrial(productId, formType);
      if ((hasRequiredFields && isRequiredFieldsMissing()) || !allowTrial) {
        dispatch(
          core.showDialog(START_TRIAL_DIALOG, {
            fields,
            product,
            hasRequiredFields
          })
        );
        return;
      }
    } else {
      if (product.allowRequestForQuoteOrDemo) {
        await submitManageSignupData(product);
      } else {
        await startProvisioning(product);
      }
    }
  }
};

export const submitManageSignupData = async product => {
  const dispatch = core.Store().dispatch;
  const result = await submitSignupData(product);
  if (result && result.formSubmitted) {
    dispatch(core.showDialog(REQUEST_FOR_QUOTE_CONFIRMATION_DIALOG));
    return result;
  }
};

export const submitFirstUsageForm = async product => {
  var partner = await getPartnerOrCache();
  var key = 'bg_submitted_' + partner.id;

  if (!getCookie(key)) {
    var fieldsResult = await ToolsService.getFormSignupFields(
      product.productId,
      formTypes.FirstUsageForm
    );
    if (!fieldsResult.isSuccess) {
      return;
    }

    var fields = fieldsResult.response;
    if (!hasVisibleFields(fields)) {
      var formData = { formType: formTypes.FirstUsageForm };
      addTrackingData(formData);
      await ToolsService.submitSignupData(product.productId, formData);
      setCookie(key, true, 365 * 100);
    }
    //for now all first usage forms don't have any visible fields to be filled by the user
    //So no else condition needed for now
  }
};

export const addTrackingData = signupData => {
  signupData.MarketoCookie = getCookie('_mkto_trk');
};

export const submitSignupData = async product => {
  const dispatch = core.Store().dispatch;
  const state = core.Store().getState();
  const productId = product.productId;
  const requiredFieldsCheck = core.getScreenData(state.screen, [
    'partnerLocation',
    'requiredFields'
  ]);
  if (requiredFieldsCheck) {
    await updateRequiredFields();
  }

  const signupDataChk = core.getScreenData(state.screen, [
    Routes.HOME_PAGE.id,
    'trialSignup',
    product.productId
  ]);
  const signupData = signupDataChk ? signupDataChk.toJS() : {};
  addTrackingData(signupData);
  const result = await ToolsService.submitSignupData(productId, signupData);
  if (!result.isSuccess || result.response.status === 'error') {
    const errorMessage = 'Failed to create product instance. ';
    const error = errorMessage + (result.error || result.response.msg);
    core.CwLog.error(error);
    dispatch(
      core.setErrorScreenMessage(errorMessage, false, result.errorMessages)
    );
    return;
  }

  const provisioningResponse = result.response;

  await refreshProducts();
  dispatch(core.setScreenData(['hub', 'selectedTab'], 'products'));
  if (provisioningResponse && provisioningResponse.redirectUrl) {
    window.open(provisioningResponse.redirectUrl);
  }

  return provisioningResponse;
};

export const getControlProduct = data => {
  return data.split(' ').splice(-1)[0];
};

export const getProductStatusLocaleKey = product => {
  if (product.provisioning) {
    return 'provisioning';
  } else if (product.trialExpired) {
    return 'trial_expired';
  } else if (product.failed) {
    return 'provisioning_failed';
  } else if (product.allowFreeTrial) {
    return 'try_free';
  } else if (product.cancelled) {
    return 'cancelled';
  } else if (product.paymentPastDue) {
    return 'payment_past_due';
  } else if (product.inTrial && product.name !== 'Unite') {
    if (product.daysLeftInTrial) {
      return 'days_in_trial';
    }

    return 'in_trial';
  } else if (product.inTrial && product.name === 'Unite') {
    return 'days_in_trial';
  } else if (product.purchased) {
    return 'your_plan';
  }
};

export const getTileButtonTextLocaleKey = product => {
  if (product.provisioning) {
    return;
  }

  if (product.allowFreeTrial) {
    switch (product.productIdentifier) {
      case 'control':
        return 'start_trial';
      case 'unite':
        return 'start_30_day_trial';
      case 'identify':
        return 'start_trial';
      default:
        return '';
    }
  }

  if (product.failed) {
    return 'contact_support';
  }

  if (product.trialExpired || product.cancelled) {
    if (product.productIdentifier === 'control') {
      return 'buy_now';
    } else if (product.allowAddToCart) {
      return 'add_to_cart';
    } else {
      return 'contact_sales';
    }
  }

  if (product.inTrial) {
    if (product.productIdentifier === 'control') {
      return 'launch';
    } else if (product.allowAddToCart) {
      return 'add_to_cart';
    } else {
      return 'contact_sales';
    }
  }

  if (product.purchased && product.paymentPastDue) {
    return 'retry_payment';
  }

  if (product.purchased) {
    return 'launch';
  }
};

export const validateCustomUrl = async customUrl => {
  const result = await ToolsService.validateCustomUrl(customUrl);
  if (!result.isSuccess || result.response.status === 'error') {
    const error =
      'Failed to validate custom URL: ' +
      customUrl +
      ' ' +
      (result.error || result.response.msg);
    core.CwLog.error(error);
    return;
  }

  return result.response;
};

export const contactSupport = () => {
  if (
    HasAccess(
      [
        POLICIES.HOME_ADMIN,
        POLICIES.HOME_ADVANCED_USER,
        POLICIES.HOME_STANDARD_USER,
        POLICIES.HOME_BASIC_USER
      ],
      null
    )
  ) {
    const baseUrl = core.getModuleConfig(core.Store().getState().module, [
      'SalesForceServiceUrlBase'
    ]);
    const url =
      baseUrl + '?startURL=' + encodeURIComponent('/s/#step2?supportArea=PS');
    window.open(url, '_self');
  } else {
    window.open('mailto:help@connectwise.com');
  }
};

export const contactSales = () => {
  window.open('mailto:accountmanager@connectwise.com');
};

export const getPartnerProductsPurchased = () => {
  const state = core.Store().getState();
  const productCheck = core.getModuleCache(state.module, ['products']);
  const products = productCheck ? productCheck.toJS() : [];
  return products.filter(
    p => (p.purchased || (p.inTrial && !p.trialExpired)) && p.productId
  );
};

export const onLauchURLClick = product => {
  const state = core.Store().getState();
  const user = core.getUser(state.oidc);
  const partnerId = core.getPartnerId(state.oidc);
  const stsBaseUrl = core.getModuleConfig(state.module, ['authority']);
  const isGuestUserLoggedIn = !!user.toJS()?.profile?.origin_partner_id;

  if (product.productIdentifier.toLowerCase() === 'platform') {
    return window.open(
      stsBaseUrl +
        '/account/switch?clientId=' +
        product.stsClientId +
        '&accountId=' +
        partnerId +
        '&redirectUri=' +
        product.launchUrl
    );
  } else if (product.productIdentifier.toLowerCase() === 'manage' && isGuestUserLoggedIn) {
    return window.open(
      stsBaseUrl +
        '/account/switch?clientId=' +
        product.stsClientId +
        '&accountId=' +
        partnerId +
        '&redirectUri=' +
        product.launchUrl
    );
  }
  else {
    return window.open(product.launchUrl);
  }
};
