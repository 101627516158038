import React from 'react';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import css from './DotTile.scss';
import cx from 'classnames';
import Locale from 'src/Locale';

const TileStatus = ({ product }) => {
  let tileStatus = null;
  if (product.inTrial || product.purchased) {
    tileStatus = (
      <div
        className={cx(css.productStatus, {
          [css.productStatusInTrial]: product.inTrial,
          [css.productStatusFree]: !product.inTrial && !product.purchased
        })}
      >
        {product.status}
      </div>
    );
  }

  tileStatus = null;
  return tileStatus;
};

const DotTile = props => {
  const { formatMessage } = props.intl;
  const { product } = props;
  let productName;
  let productNameForTitle;
  switch (product.name) {
    case 'Manage':
      productName = 'PSA';
      productNameForTitle = 'PSA™';
      break;
    case 'Sell':
      productName = 'CPQ';
      productNameForTitle = 'CPQ™';
      break;
    case 'Unite':
      productName = 'Cloud Billing';
      productNameForTitle = 'Cloud Billing';
      break;
    case 'Identify':
      productName = 'Identify Assessment';
      productNameForTitle = 'Identify Assessment™';
      break;
    case 'Control':
      productName = 'ScreenConnect';
      productNameForTitle = 'ScreenConnect™';
      break;
    case 'BrightGauge':
      productNameForTitle = 'BrightGauge™';
      break;
    default:
      productName = product.name;
      productNameForTitle = product.name;
      break;
  }

  const onLaunchClick = (product, automationName) => {
    core.addClickTrack(automationName);
    props.onLauchURLClick(product);
  };
  
  return product ? (
    <>
      <TileStatus product={product} formatMessage={props.intl.formatMessage} />
      <core.Flexbox
        onClick={() => onLaunchClick(product, props.automationName)}
        className={`css.productTile ${
          props.customStyle ? props.customStyle : css.productTile
        }`}
        column
      >
        <span
          className={cx(css.productNameOverflow, css.productName)}
          title={productNameForTitle}
        >
          {!productName ? product.name : productName}
          <sup className={css.tradeMarkStyle}>
            {(product.name === 'Manage' ||
              product.name === 'Sell' ||
              product.name === 'Identify' ||
              product.name === 'BrightGauge' ||
              product.name === 'Control') &&
              formatMessage(Locale.branding_trademark)}
          </sup>
        </span>
        {(product.productIdentifier !== 'identify' ||
          product.productIdentifier !== 'unite') && (
          <span className={css.productInfo} title={product.productInfo2}>
            {product.productInfo2}
          </span>
        )}
      </core.Flexbox>
    </>
  ) : (
    <div>{formatMessage(Locale.dots_noproducts)}</div>
  );
};

export default injectIntl(DotTile);
