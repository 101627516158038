import { CircularProgress } from '@mui/material';
import React from 'react';

const Loader = () => {
    return (
        <div style=
            {{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 'auto'
            }}>
            <CircularProgress sx={{height: 'auto !important', width: 'auto !important'}}/>
        </div>
    );
};

export default Loader;
