import { Dialog } from '@cw-ui/feedback-components';
import {
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Container,
  IconButton,
  Stack,
  ThemeProvider,
  Typography
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Button } from '@cw-ui/form-components';
import { getTheme } from '@cw-ui/asio-neon-themes';
import { Card } from '@cw-ui/surface-components';
import { Link } from '@cw-ui/navigation-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { FeedbackCardList } from 'src/utils';
import TextFeild from './TextFeild';
import Feedbacklink from './FeedbackLink';

const FeedbackDialog = props => {
  const [expanded, setExpanded] = useState(null);
  const [inputExpanded, setInputExpanded] = useState(null);

  const theme = getTheme('light');

  const handleExpandClick = useCallback(
    id => {
      setExpanded(expanded === id ? null : id);
      setInputExpanded(inputExpanded === id ? id : null);

    },
    [expanded, inputExpanded]
  );

  const handleInputExpandClick = useCallback(
    id => {
      setInputExpanded(inputExpanded === id ? null : id);
    },
    [inputExpanded]
  );

  const FeedbackCardDetails = () => {
    return FeedbackCardList.map(item => {
      return (
        <Card key={item.id}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <CardContent>
              <Typography variant="body2">{item.title}</Typography>
            </CardContent>
            <CardActions>
              <IconButton onClick={() => handleExpandClick(item.id)}>
                {expanded === item.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </CardActions>
          </div>
          <Collapse in={expanded === item.id} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography
                sx={{
                  fontSize: '12px',
                  whiteSpace: 'break-spaces'
                }}
              >
                {item.description}
              </Typography>
              {!inputExpanded && (
                <Link
                  underline="hover"
                  onClick={() => handleInputExpandClick(item.id)}
                  variant="body3"
                >
                  {item.link}
                </Link>
              )}
              <Collapse
                in={inputExpanded === item.id}
                timeout="auto"
                unmountOnExit
              >
                <TextFeild item={item} />
                <Feedbacklink
                  item={item}
                  onFeedbackSubmitClick={props.onFeedbackSubmitClick}
                />
              </Collapse>
            </CardContent>
          </Collapse>
        </Card>
      );
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open
        sx={{
          '& .MuiDialog-paper': {
            height: '90vh',
            width: '70vw'
          }
        }}
        maxWidth="lg"
        onClose={props.onCloseIconClick}
      >
        <Container
          sx={{
            maxWidth: '100% !important',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: '1%',
            position: 'sticky',
            borderBottom: '1px solid #ccc',
            top: 0,
            zIndex: 1,
            backgroundColor: '#ffffff'
          }}
        >
          <Typography sx={{ fontSize: '16px' }}>
            How Can We Help You?
          </Typography>
          <CloseOutlinedIcon
            sx={{ color: '#27366D' }}
            onClick={props.onCloseIconClick}
          />
        </Container>
        <div
          style={{ display: 'flex', alignItems: 'start', flexDirection: 'row' }}
        >
          <CardContent sx={{ width: '60%' }}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ fontSize: '30px' }}
            >
              Welcome to the Frequently Asked Questions (FAQs) section for
              issues related to activating your ASIO instance.
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                fontSize: '16px'
              }}
            >
              If you are facing any difficulties during the setup process, this
              guide aims to provide answers and solutions to common problems.
              Please review the following questions and answers to troubleshoot
              and resolve any issues you may encounter while activating your
              ASIO instance.
            </Typography>
            <Stack gap={0.2}>
              <FeedbackCardDetails />
            </Stack>
          </CardContent>
          <CardMedia
            component="img"
            image="/images/AsioPage/DialogError.png"
            alt="feedback"
            sx={{ marginTop: '8%', width: '500px' }}
          />
        </div>
        <CardActions
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            padding: '8px',
            height: '100%',
            alignItems: 'flex-end',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button
            color="ghost"
            onClick={props.onPreviousButtonClick}
            variant="outlined"
          >
            Previous
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={props.onCloseIconClick}
          >
            Close
          </Button>
        </CardActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default injectIntl(FeedbackDialog);
