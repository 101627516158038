import { connect } from 'react-redux';
import Routes from 'src/services/Routes';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import ControlProductDrawer from './ControlProductDrawer';
import Locale from 'src/Locale';
import {
  startTrial,
  getProductStatusLocaleKey,
  getTileButtonTextLocaleKey,
  getControlProduct
} from 'src/services/Products/ProductsService';
import { WALLET_DRAWER } from 'src/components/Drawer/PartnerWalletDrawer/WalletDrawerContainer';
import { POLICIES } from 'src/services/Authorization';
import HasAccess from 'src/services/RoleAccess';

export const CONTROL_PRODUCT_DRAWER = 'CONTROL_PRODUCT_DRAWER';

const mapStateToProps = state => {
  const productChk = core.getScreenData(
    state.screen,
    ['product'],
    CONTROL_PRODUCT_DRAWER
  );
  const product = productChk ? productChk.toJS() : undefined;

  const tileButtonTextLocaleKey = getTileButtonTextLocaleKey(product);

  const isBusySupport =
    core.getScreenData(state.screen, ['isProcessingSupportButtonClick']) ===
    true;

  const isBusyAccess =
    core.getScreenData(state.screen, ['isProcessingAccessButtonClick']) ===
    true;

  const pricingPlansChk = core.getScreenData(
    state.screen,
    ['pricingPlans'],
    CONTROL_PRODUCT_DRAWER
  );
  const pricingPlans = pricingPlansChk ? pricingPlansChk.toJS() : [];

  const currencyFormat = {
    style: 'currency',
    currency: pricingPlans[0].currency,
    minimumFractionDigits: 0
  };

  const hasAccessToTrial = HasAccess(
    [POLICIES.ADMIN, POLICIES.BILLING_ADMIN],
    null
  );

  const controlProduct =
    product.controlDualTrial && getControlProduct(product.controlDualTrial);

  const isTrialEnable = name => {
    return hasAccessToTrial
      ? controlProduct
        ? controlProduct === name
        : true
      : false;
  };

  const statusLocaleKey = product && getProductStatusLocaleKey(product);
  return {
    disableButtonTitle: !hasAccessToTrial
      ? core.formatMessage(Locale.unauthorize_disable_button_title_text)
      : '',
    productName: core.formatMessage(Locale.drawer_control_title),
    productDescription: core.formatMessage(Locale.drawer_control_description),
    productLearnMoreText: core.formatMessage(Locale.learn_more),
    planDescription: core.formatMessage(Locale.drawer_control_plan_description),
    planCompareText: core.formatMessage(Locale.compare_plan),
    planOwnedDescription: core.formatMessage(Locale.control_onwned_description),
    planOwnedText: core.formatMessage(Locale.control_onwned_text),
    isBusySupport,
    isBusyAccess,
    plans: product && [
      {
        name: 'Support',
        isTrialEnable: isTrialEnable('Support'),
        description: null,
        imageSrc: '/images/HomePage/support-group.png',
        buttonTitle: hasAccessToTrial
          ? isTrialEnable('Support')
            ? ''
            : core.formatMessage(Locale.trial_started_Title)
          : core.formatMessage(Locale.unauthorize_disable_button_title_text),
        buttonText:
          controlProduct === 'Support'
            ? core.formatMessage(Locale[tileButtonTextLocaleKey])
            : core.formatMessage(Locale.start_trial),
        automationName: `Control_${tileButtonTextLocaleKey}_support`,
        pricingQuote: core.formatMessage(Locale.billing_pricing_quote),
        price: core.formatNumber(pricingPlans[0].price / 12, currencyFormat),
        priceInterval: `/${core.formatMessage(Locale.license_per_month)}`,
        billingType: core.formatMessage(Locale.control_support_billing_type),
        status:
          controlProduct === 'Support'
            ? statusLocaleKey
              ? core.formatMessage(Locale[statusLocaleKey], {
                  days: product.daysLeftInTrial
                })
              : ''
            : core.formatMessage(Locale.try_free),
        featureDescription: core.formatMessage(
          Locale.drawer_control_support_feature
        ),
        features: [
          core.formatMessage(Locale.remote_access),
          core.formatMessage(Locale.remote_support),
          core.formatMessage(Locale.remote_meeting),
          core.formatMessage(Locale.remote_app),
          core.formatMessage(Locale.video_auditing),
          core.formatMessage(Locale.connectwise_view)
        ],
        statusKey: statusLocaleKey,
        featuresCondition: core.formatMessage(
          Locale.drawer_control_support_feature_condition
        )
      },
      {
        name: 'Access',
        isTrialEnable: isTrialEnable('Access'),
        description: null,
        imageSrc: '/images/HomePage/access-group.png',
        buttonTitle: hasAccessToTrial
          ? isTrialEnable('Access')
            ? ''
            : core.formatMessage(Locale.trial_started_Title)
          : core.formatMessage(Locale.unauthorize_disable_button_title_text),
        buttonText:
          controlProduct === 'Access'
            ? core.formatMessage(Locale[tileButtonTextLocaleKey])
            : core.formatMessage(Locale.start_trial),
        automationName: `Control_${tileButtonTextLocaleKey}_access`,
        pricingQuote: core.formatMessage(Locale.billing_pricing_quote),
        price: core.formatNumber(pricingPlans[1].price / 12, currencyFormat),
        priceInterval: `/${core.formatMessage(Locale.license_per_month)}`,
        billingType: core.formatMessage(Locale.control_access_billing_type),
        status:
          controlProduct === 'Access'
            ? statusLocaleKey
              ? core.formatMessage(Locale[statusLocaleKey], {
                  days: product.daysLeftInTrial
                })
              : ''
            : core.formatMessage(Locale.try_free),
        featureDescription: core.formatMessage(
          Locale.drawer_control_access_feature
        ),
        features: [
          core.formatMessage(Locale.unlimited_concurrent_technicians),
          core.formatMessage(Locale.remote_access),
          core.formatMessage(Locale.mobile_app),
          core.formatMessage(Locale.agent_deployer),
          core.formatMessage(Locale.windows_updates),
          core.formatMessage(Locale.preconfigured_reports)
        ],
        statusKey: statusLocaleKey
      }
    ]
  };
};

const showWalletDrawer = () => {
  const dispatch = core.Store().dispatch;
  dispatch(core.hideDrawer(CONTROL_PRODUCT_DRAWER));
  dispatch(core.showDrawer(WALLET_DRAWER));
};

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => {
      dispatch(core.hideDrawer(CONTROL_PRODUCT_DRAWER));
      dispatch(core.setScreenData(['isProcessingSupportButtonClick'], false));
      dispatch(core.setScreenData(['isProcessingAccessButtonClick'], false));
    },
    onUniversityLinkClick: () => {
      window.open(
        'https://www.connectwise.com/platform/unified-management/control'
      );
    },
    onComparePlanLinkClick: () => {
      window.open(
        'https://www.connectwise.com/software/control/pricing/compare'
      );
    },
    onConnectLinkClick: () => {
      window.open(
        'https://docs.connectwise.com/ConnectWise_Control_Documentation/Get_started/Administration_page/Security_page/User_sources/Configure_ConnectWise_Control_for_ConnectWise_SSO'
      );
    },
    onCardButtonClick: async plan => {
      const state = core.Store().getState();
      const productChk = core.getScreenData(
        state.screen,
        ['product'],
        CONTROL_PRODUCT_DRAWER
      );
      const product = productChk ? productChk.toJS() : undefined;
      let result;
      if (plan.name) {
        if (plan.name === 'Support') {
          dispatch(
            core.setScreenData(['isProcessingSupportButtonClick'], true)
          );
        } else {
          dispatch(core.setScreenData(['isProcessingAccessButtonClick'], true));
        }

        if (product.allowFreeTrial) {
          const dualTrial =
            plan.name.toLowerCase() === 'support'
              ? core.formatMessage(Locale.connectwise_control_support)
              : core.formatMessage(Locale.connectwise_control_access);
          dispatch(
            core.setScreenData(
              [
                Routes.HOME_PAGE.id,
                'trialSignup',
                product.productId,
                'Trial CW Control Dual Trial Selections'
              ],
              dualTrial
            )
          );

          result = await startTrial(product);
          dispatch(
            core.setScreenData(['isProcessingSupportButtonClick'], false)
          );
          dispatch(
            core.setScreenData(['isProcessingAccessButtonClick'], false)
          );
          return result;
        }

        if (product.purchased && product.paymentPastDue) {
          return showWalletDrawer();
        }

        if (product.trialExpired || product.cancelled) {
          return window.open(product.purchaseUrl);
        }

        if (product.purchased || product.inTrial) {
          const user = core.getUser(state.oidc);
          const isGuestUserLoggedIn = !!user.toJS()?.profile?.origin_partner_id;
          const partnerId = core.getPartnerId(state.oidc);
          if (isGuestUserLoggedIn) {
          const stsBaseUrl = core.getModuleConfig(state.module, ['authority']);
            return window.open(
              stsBaseUrl + '/account/switch?clientId='+ product.stsClientId+'&accountId=' +
                partnerId +
                '&redirectUri=' +
                product.launchUrl
            );
          } else {
            return window.open(product.launchUrl);
          }
        }
      }
    }
  };
};

const ControlProductDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlProductDrawer);

export default injectIntl(ControlProductDrawerContainer);
