import React from "react";
import { Typography } from "@mui/material";
import { STATUS_TYPE } from "../../utils";

const Status = ({ type, children }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "4px",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div
        style={{
          minHeight: "8px",
          minWidth: "8px",
          borderRadius: "50%",
          backgroundColor: STATUS_TYPE[type],
          border: "1px solid #ECECEE",
        }}
      ></div>
      <Typography
        component="p"
        sx={{
          fontSize: "12px",
          textTransform: "capitalize",
          textAlign: "center",
        }}
      >
        {children}
      </Typography>
    </div>
  );
};

export default Status;
