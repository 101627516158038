import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Manager, Popper, Reference } from 'react-popper';
import cx from 'classnames';
import css from './UserAccountPopover.scss';
import UserProfileBriefing from 'src/components/UserAccount/UserProfileBriefing';
import Avatar from 'src/components/Avatar/Avatar';
import DismissContextHOC from 'src/components/HoC/DismissContextHoc';

function UserAccountPopover(props) {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isUrlUpdated, setIsUrlUpdated] = useState(false);
  const { onMenuItemClick, showProfileDrawer } = props;
  let avatar = '';
  if (props.hasPopup) {
    avatar = (
      <Avatar
        style={props.avatarStyle}
        initials={props.initials}
        src={props.imgSrc}
      />
    );
  } else {
    avatar = (
      <Avatar
        automationName="avatar"
        style={props.avatarStyle}
        initials={props.initials}
        src={props.imgSrc}
        label={props.label}
        onClick={() => onMenuItemClick('updateProfile')}
      />
    );
  }

  const popper = (
    <UserProfileBriefing
      Avatar={() => (
        <Avatar
          initials={props.initials}
          src={props.imgSrc}
          popover={css.container}
          className={css.avatar}
        />
      )}
      name={props.name}
      email={props.email}
      items={props.items}
      companyName={props.companyName}
      role={props.role}
      onMenuItemClicked={id => {
        setIsShowPopup(false);
        props.onMenuItemClick(id);
      }}
    />
  );

  const popperWithDismissContext = DismissContextHOC(popper, () =>
    setIsShowPopup(!isShowPopup)
  );

  useEffect(() => {
    if (!isUrlUpdated && showProfileDrawer) {
      onMenuItemClick('updateProfile');
      setIsUrlUpdated(true);
    }
  }, [onMenuItemClick, showProfileDrawer, isUrlUpdated]);

  if (!props.hasPopup) {
    return avatar;
  } else {
    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              ref={ref}
              className={cx(props.className, css.wrap)}
              onClick={e => {
                setIsShowPopup(!isShowPopup);
                e.stopPropagation();
              }}
              tabIndex={-1}
            >
              {avatar}
            </div>
          )}
        </Reference>
        {isShowPopup && (
          <Popper placement="right-end">
            {({ ref, style, placement }) => (
              <div
                ref={ref}
                data-placement={placement}
                style={style}
                className={css.popover}
              >
                {popperWithDismissContext}
              </div>
            )}
          </Popper>
        )}
      </Manager>
    );
  }
}

UserAccountPopover.propTypes = {
  avatarStyle: PropTypes.object,
  hasPopup: PropTypes.bool.isRequired,
  items: PropTypes.array,
  onMenuItemClick: PropTypes.func,
  className: PropTypes.string,
  initials: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  imgSrc: PropTypes.string,
  popup: PropTypes.element,
  companyName: PropTypes.string,
  role: PropTypes.string
};

export default UserAccountPopover;
