import { connect } from 'react-redux';
import Routes from 'src/services/Routes';
import { injectIntl } from 'react-intl';
import * as core from 'cw-ui-core';
import SellProductDrawer from 'src/components/Drawer/ProductDrawer/SellProductDrawer';
import {
  startTrial,
  getTileButtonTextLocaleKey,
  formTypes
} from 'src/services/Products/ProductsService';
import Locale from 'src/Locale';
import { POLICIES } from 'src/services/Authorization';
import HasAccess from 'src/services/RoleAccess';

export const SELL_PRODUCT_DRAWER = 'SELL_PRODUCT_DRAWER';

const mapStateToProps = state => {
  const productChk = core.getScreenData(
    state.screen,
    ['product'],
    SELL_PRODUCT_DRAWER
  );
  const product = productChk && productChk.toJS();

  const isBusyDemo =
    core.getScreenData(
      state.screen,
      ['isProcessingDemoButtonClick'],
      SELL_PRODUCT_DRAWER
    ) === true;

  const isBusyQuote =
    core.getScreenData(
      state.screen,
      ['isProcessingQuoteButtonClick'],
      SELL_PRODUCT_DRAWER
    ) === true;

  const hasAccessToDemoOrQuote = HasAccess(
    [POLICIES.ADMIN, POLICIES.BILLING_ADMIN],
    null
  );

  const plans = product && [
    //Uncomment when Request a Demo api and interface is ready
    // {
    //   name: 'Demo',
    //   buttonText: product.allowRequestForQuoteOrDemo
    //     ? core.formatMessage(Locale.request_demo_button)
    //     : core.formatMessage(Locale[getTileButtonTextLocaleKey(product)]),
    //   automationName: 'sell_demo',
    // },
    {
      name: 'Quote',
      buttonText: product.allowRequestForQuoteOrDemo
        ? core.formatMessage(Locale.request_quote_button)
        : core.formatMessage(Locale[getTileButtonTextLocaleKey(product)]),
      automationName: 'sell_quote'
    }
  ];

  const benefits = [
    {
      name: 'template',
      imageSrc: 'images/drawer/template-gray.jpg',
      title: core.formatMessage(Locale.drawer_sell_template_title),
      description: core.formatMessage(Locale.drawer_sell_template_text)
    },
    {
      name: 'search',
      imageSrc: 'images/drawer/bom-gray.jpg',
      title: core.formatMessage(Locale.drawer_sell_search_title),
      description: core.formatMessage(Locale.drawer_sell_search_text)
    },
    {
      name: 'flexible',
      imageSrc: 'images/drawer/automated-scripting-gray.jpg',
      title: core.formatMessage(Locale.drawer_sell_flexible_title),
      description: core.formatMessage(Locale.drawer_sell_flexible_text)
    },
    {
      name: 'quotes',
      imageSrc: 'images/drawer/coding-scripting-gray.jpg',
      title: core.formatMessage(Locale.drawer_sell_quotes_title),
      description: core.formatMessage(Locale.drawer_sell_quotes_text)
    },
    {
      name: 'cloud',
      imageSrc: 'images/drawer/cloud-services-gray.jpg',
      title: core.formatMessage(Locale.drawer_sell_cloud_title),
      description: core.formatMessage(Locale.drawer_sell_cloud_text)
    },
    {
      name: 'intelligence',
      imageSrc: 'images/drawer/idea-gray.jpg',
      title: core.formatMessage(Locale.drawer_sell_intelligence_title),
      description: core.formatMessage(Locale.drawer_sell_intelligence_text)
    }
  ];

  return {
    isDemoOrQuoteEnable: hasAccessToDemoOrQuote,
    buttonTitle: hasAccessToDemoOrQuote
      ? ''
      : core.formatMessage(Locale.unauthorize_disable_button_title_text),
    productName: core.formatMessage(Locale.drawer_sell_title),
    productDescription: core.formatMessage(Locale.drawer_sell_description),
    productLearnMoreText: core.formatMessage(Locale.learn_more),
    isBusyDemo,
    isBusyQuote,
    plans,
    benefits
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => {
      dispatch(core.hideDrawer(SELL_PRODUCT_DRAWER));
      dispatch(
        core.setScreenData(
          ['isProcessingDemoButtonClick'],
          false,
          SELL_PRODUCT_DRAWER
        )
      );
      dispatch(
        core.setScreenData(
          ['isProcessingQuoteButtonClick'],
          false,
          SELL_PRODUCT_DRAWER
        )
      );
    },
    onUniversityLinkClick: () => {
      window.open(
        'https://www.connectwise.com/globalassets/media/assets/feature-sheet/sellfeaturesheet.pdf'
      );
    },
    onConnectLinkClick: () => {
      window.open(
        'https://docs.connectwise.com/ConnectWise_Sell/800/010/040/SSO_Setup_for_ConnectWise_Sell'
      );
    },
    onCardButtonClick: async plan => {
      const state = core.Store().getState();
      const productChk = core.getScreenData(
        state.screen,
        ['product'],
        SELL_PRODUCT_DRAWER
      );
      const product = productChk ? productChk.toJS() : undefined;
      if (product.allowRequestForQuoteOrDemo) {
        if (plan.name === 'Demo') {
          dispatch(
            core.setScreenData(
              ['isProcessingDemoButtonClick'],
              true,
              SELL_PRODUCT_DRAWER
            )
          );
          dispatch(
            core.setScreenData(
              [
                Routes.HOME_PAGE.id,
                'trialSignup',
                product.productId,
                'formType'
              ],
              formTypes.Demo
            )
          );
        } else if (plan.name === 'Quote') {
          dispatch(
            core.setScreenData(
              ['isProcessingQuoteButtonClick'],
              true,
              SELL_PRODUCT_DRAWER
            )
          );
          dispatch(
            core.setScreenData(
              [
                Routes.HOME_PAGE.id,
                'trialSignup',
                product.productId,
                'formType'
              ],
              formTypes.Quote
            )
          );
        }

        const result = await startTrial(product);
        if (plan.name === 'Demo') {
          dispatch(
            core.setScreenData(
              ['isProcessingDemoButtonClick'],
              false,
              SELL_PRODUCT_DRAWER
            )
          );
        } else if (plan.name === 'Quote') {
          dispatch(
            core.setScreenData(
              ['isProcessingQuoteButtonClick'],
              false,
              SELL_PRODUCT_DRAWER
            )
          );
        }

        return result;
      }

      if (product.purchased) {
        const partnerId = core.getPartnerId(state.oidc);
        const user = core.getUser(state.oidc);
        const isGuestUserLoggedIn = !!user.toJS()?.profile?.origin_partner_id;
        if (isGuestUserLoggedIn) {
          const stsBaseUrl = core.getModuleConfig(state.module, ['authority']);
          return window.open(
            stsBaseUrl + '/account/switch?clientId='+ product.stsClientId+'&accountId=' +
              partnerId +
              '&redirectUri=' +
              product.launchUrl
          );
        } else {
          return window.open(product.launchUrl);
        }
      }
    }
  };
};

const SellProductDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SellProductDrawer);

export default injectIntl(SellProductDrawerContainer);
