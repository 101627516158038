import * as React from 'react';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActions, Container, Dialog, ThemeProvider } from '@mui/material';
import { Button } from '@cw-ui/form-components';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { getTheme } from '@cw-ui/asio-neon-themes';
import { formatMessage } from 'cw-ui-core';
import Locale from 'src/Locale';

const FeedbackResolved = props => {
  const theme = getTheme('light');
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open
        sx={{
          '& .MuiDialog-paper': {
            height: '90vh',
            width: '70vw'
          }
        }}
        maxWidth="lg"
      >
        <Container
          sx={{
            maxWidth: '100% !important',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: '1%',
            position: 'sticky',
            borderBottom: '1px solid #ccc',
            top: 0,
            zIndex: 1,
            backgroundColor: '#ffffff'
          }}
        >
          <Typography sx={{ fontSize: '16px' }}>
            How Can We Help You?
          </Typography>
          <CloseOutlinedIcon
            sx={{ color: '#27366D' }}
            onClick={props.onCloseIconClick}
          />
        </Container>
        <div
          style={{ display: 'flex', alignItems: 'start', flexDirection: 'row' }}
        >
          <CardContent sx={{ width: '50%' }}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ fontSize: '30px' }}
            >
              {formatMessage(Locale.asio_case_title, {
                caseNumber: props.caseNumber
              })}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                fontSize: '16px'
              }}
            >
              We apologize for any inconvenience this may have caused. Our
              dedicated support team will work diligently to rectify the
              situation and ensure a successful upgrade to ASIO as soon as
              possible. Rest assured that we are committed to providing you with
              a seamless experience and will keep you updated on the progress
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                fontSize: '16px',
                marginTop: '2%'
              }}
            >
              You can use this case number to easily monitor the status of your
              issue. Our support team will be in touch with you shortly to
              assist you further.You can use this case number to easily monitor
              the status of your issue. Our support team will be in touch with
              you shortly to assist you further.
            </Typography>
          </CardContent>
          <CardMedia
            component="img"
            image="/images/feedback.svg"
            alt="feedback"
            sx={{ marginTop: '8%', width: '500px' }}
          />
        </div>
        <CardActions
          sx={{
            position: 'sticky',
            height: '100%',
            bottom: 0,
            backgroundColor: 'white',
            padding: '8px',
            alignItems: 'flex-end',
            display: 'flex',
            justifyContent: 'end'
          }}
        >
          <Button
            sx={{ padding: '8px' }}
            color="primary"
            variant="outlined"
            onClick={props.onCloseIconClick}
          >
            Close
          </Button>
          <Button
            color="primary"
            onClick={() => window.open(props.caseUrl, '_blank')}
            variant="contained"
          >
            View Case
          </Button>
        </CardActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default FeedbackResolved;